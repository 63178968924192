<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo-bancannabis-black
            v-show="isDark"
            v-if="!isZero"
            class="w-100"
          />
          <header-egroweed-nft
            v-show="!isDark"
            v-if="!isZero"
            class="w-100"
          />
          <zerocarnizero-logo
            v-if="isZero"
            class="w-100"
          />

        </b-link>

        <b-card-title class="mb-1">
          Be part of the
          {{ isZero ? " burger evolution 🍔" : " re-evolution 🚀" }}
        </b-card-title>
        <b-card-text class="mb-2">
          You're one step away from
          {{ isZero ? " being part of the evolution of vegan food." : " becoming an e-grower of Cannabis" }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Repeat password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password2"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="is-egrower"
                v-model="isGrowerMaster"
                name="checkbox-2"
              >
                I represent a company.
              </b-form-checkbox>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                {{ isZero ? "privacy policy & terms" : "" }}
                <b-link
                  v-if="!isZero"
                  v-b-modal.modal-
                >
                  privacy policy & terms
                </b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="doRegisterRequest"
              :style="{'background-color':isZero?'#75c469 !important':'', 'border-color': 'white !important'}"
            >
              Sign up
              <b-spinner
                v-show="doRegisterRequest"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link
            :to="{name:'login', params: { iz: isZero}}"
          >
            <span :style="{'color':isZero?'#75c469':''}">Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>

    <b-modal
      id="modal-center"
      centered
      title="Terms and Conditions"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <div
          class="vx-col w-full"
          style="border: 1px solid forestgreen; margin:1px; text-align:justify;height: 400px;overflow-y: scroll;"
        >
          <div style="width:100%; text-align:center">
            <img
              src="@/assets/images/pages/ilustracion500x500.png"
              style="width:200px; display: inline;"
              alt="Bancannabis Login"
            >
          </div>
          <p>&nbsp;</p>
          <p class="text-center">
            <strong>T&Eacute;RMINOS Y CONDICIONES GENERALES PARA USUARIOS -&nbsp; USO Y FUNCIONAMIENTO DE LOS BIENES Y SERVICIOS OFERTADOS POR BANCANNAPPS S.A.S</strong>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul style="float:left">
            <li><strong>1. &nbsp;<strong>ACEPTACI&Oacute;N</strong></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">
            A trav&eacute;s de este documento, BANCANNAPPS S.A.S ("BANCANNABIS", "nosotros", "nuestro", "nos")&nbsp;
            establece los t&eacute;rminos y condiciones de funcionamiento de su plataforma www.e-groweed.com ("LA PLATAFORMA")
            y determina la forma en que cada USUARIO se relaciona con aquella y los servicios que all&iacute; se ofrecen.&nbsp;
            Hacer uso de la plataforma o los servicios que se ofrece en ella, cualquiera de ellos, se considera una aceptaci&oacute;n
            voluntaria, libre y expresa de cada una de las cl&aacute;usulas que se encuentran a continuaci&oacute;n; si usted no est&aacute;
            de acuerdo con el uso o el servicio ofrecido, por favor abst&eacute;ngase de utilizar la plataforma o de solicitar la prestaci&oacute;n
            de los servicios que aquel ofrece.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">
            Nuestros USUARIOS deben leer, comprender y aceptar todas las condiciones establecidas en los presentes
            T&eacute;rminos y Condiciones Generales, en las Pol&iacute;ticas de Privacidad y en los dem&aacute;s
            documentos incorporados por referencia a este documento, previo a su registro en&nbsp; BANCANNABIS&nbsp;
          </span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS no es una instituci&oacute;n financiera. Es un proveedor de tecnolog&iacute;a.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">El token Bancannabis BCA no es una moneda, es un medio de cambio o utility token&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS hace uso del Utility Token o token de utilidad; este es
            un token de acceso a la futura plataforma (Network Access Token), con el fin de intercambiar bienes y servicios
            ofrecidos en la PLATAFORMA . Por tanto, el pago recibido es un anticipo de los bienes y servicios ofrecidos en el sitio web.
          </span>
          </p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En caso que se presente un inconveniente, duda o sugerencia con el uso de la plataforma de
               BANCANNABIS o de los presentes T&eacute;rminos y Condiciones de Uso del Sitio y sus servicios, puede dirigir un correo
               electr&oacute;nico con la solicitud y datos de contacto al correo electr&oacute;nico </span>
            <a href="mailto:contact@bancannabis.org"><span style="font-weight: 400;">c</span><span style="font-weight: 400;">ontact@bancannabis.org</span></a></p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong>2. &nbsp;<strong>&nbsp;INFORMACI&Oacute;N</strong></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Esta plataforma y su contenido se proporcionan &uacute;nicamente con fines informativos y comerciales.
            La informaci&oacute;n de esta plataforma no est&aacute; destinada a proporcionar informaci&oacute;n m&eacute;dica completa.
            SI USTED TIENE UNA CONDICI&Oacute;N M&Eacute;DICA, CONSULTE INMEDIATAMENTE A SU PROPIO M&Eacute;DICO O PROVEEDOR DE ATENCI&Oacute;N M&Eacute;DICA.
            NO OFRECEMOS DIAGN&Oacute;STICO M&Eacute;DICO O CONSEJOS DE TRATAMIENTO ESPEC&Iacute;FICOS PARA EL PACIENTE.
            Siempre debe obtener informaci&oacute;n m&eacute;dica completa sobre el uso de cualquier producto o servicio
            que pueda estar contenido en este sitio web (incluidos sus usos m&eacute;dicos beneficiosos y posibles efectos adversos)
            discutiendo su uso apropiado directamente con su m&eacute;dico o, cuando corresponda, con otro asesor m&eacute;dico.
            Los pacientes, m&eacute;dicos y otros profesionales m&eacute;dicos deben consultar con los recursos m&eacute;dicos locales y
            las autoridades reguladoras para obtener informaci&oacute;n apropiada para su pa&iacute;s. Usted acepta que no tomar&aacute;
            ninguna decisi&oacute;n m&eacute;dica o relacionada con la salud basada en todo o en parte en cualquier contenido de este sitio web.
          </span>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong>3. &nbsp;<strong>&Aacute;MBITO DE APLICACI&Oacute;N</strong></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En la prestaci&oacute;n del servicio en la PLATAFORMA surge una relaci&oacute;n contractual
            entre BANCANNABIS y el USUARIO. Estos servicios tendr&aacute;n como destinatario &uacute;nico y exclusivo el USUARIO,
            por lo que su derecho sobre la prestaci&oacute;n del servicio no se puede ceder a terceros ni ser utilizados por otras
            personas distintas al USUARIO, salvo previa autorizaci&oacute;n expresa y escrita de BANCANNABIS</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNAPPS S.A.S, sociedad comercial con domicilio en la ciudad de Medell&iacute;n, departamento
            de Antioquia en la Rep&uacute;blica de Colombia, identificada con NIT No. (asignación pendiente), se hace responsable de&nbsp; los derechos y
            obligaciones inherentes al ejercicio de la prestaci&oacute;n del servicio. BANCANNABIS asume plena responsabilidad
            respecto a los servicios prestados en su nombre por profesionales contratados por BANCANNAPPS S.A.S, tambi&eacute;n por
            los profesionales con quienes se hayan suscrito acuerdos de colaboraci&oacute;n o empleados de BANCANNABIS&nbsp; o por
            cualquier tercero representante de BANCANNABIS, autorizado de conformidad con los acuerdos logrados con el tercero.
          </span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS de&nbsp; BANCANNABIS reconocen y aceptan que, en la PLATAFORMA pueden converger las siguientes
            calidades:</span></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">a.&nbsp;<span style="font-weight: 400;">USUARIO COLABORADOR: Es aquella persona natural o jur&iacute;dica que
              cuenta con un proyecto de uso de semillas, de producci&oacute;n, de transformaci&oacute;n o de distribuci&oacute;n del cannabis psicoactivo
              o no psicoactivo con una licencia vigente expedida por una autoridad competente (el &ldquo;USUARIO COLABORADOR&rdquo;) y que quiera realizar un
              crowdfounding o prestar sus servicios a nivel global.</span></li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">b.&nbsp;<span style="font-weight: 400;">USUARIO E-GROWER: Es aquella persona natural o jur&iacute;dica que tiene
              la intenci&oacute;n de realizar una inversi&oacute;n para cultivar digitalmente el cannabis por medio de la compra de un token de utilidad
              &ldquo;BCA&rdquo; para intercambiar en la PLATAFORMA por bienes y servicios entorno al cultivo (el &ldquo;USUARIO E-GROWER&rdquo;).</span></li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">c.&nbsp;<span style="font-weight: 400;">USUARIO COMUNIDAD: Es aquella persona natural o jur&iacute;dica que
              tiene la intenci&oacute;n de intercambiar tokens de utilidad BCA por bienes y/o servicios ofertados en la PLATAFORMA de BANCANNABIS.</span></li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p>&nbsp;</p>
          <ul>
            <li><strong>4.&nbsp;<strong>DEFINICIONES</strong></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>USUARIO:</strong><span style="font-weight: 400;"> Es la persona natural o jur&iacute;dica que acepta intercambios electr&oacute;nicos
            a trav&eacute;s de LA PLATAFORMA para acceder a los bienes y / o servicios ofrecidos por la empresa BANCANNAPPS S.A.S.</span></p>
          <p>&nbsp;</p>
          <p><strong>ALIADOS: </strong><span style="font-weight: 400;">Es la persona natural o jur&iacute;dica que establece un acuerdo con BANCANABIS
            para proveer alg&uacute;n bien o servicio en la PLATAFORMA o en la cadena de producci&oacute;n del cannabis.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><strong>BIENES Y / O SERVICIOS O PRODUCTOS</strong><span style="font-weight: 400;">: Son los bienes y/o servicios ofertados en la PLATAFORMA
            por parte de BANCANNABIS o&nbsp; sus aliados, para intercambiar a partir del Token de utilidad de BANCANNABIS que se llama &ldquo;BCA&rdquo;.&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p><strong>PLATAFORMA:</strong><span style="font-weight: 400;"> Se refiere a la estructura t&eacute;cnica, funcional y organizativa administrada
            por el administrador, que permite a los usuarios entablar y concretar compras y ventas de servicios con tokens BCA y/o monedas Digitales
            mediante una red P2P.</span></p>
          <p>&nbsp;</p>
          <p><strong>CUENTA DE USUARIO:</strong><span style="font-weight: 400;"> Es un espacio digital que tiene asignada una clave privada y una clave
            p&uacute;blica con la cual se protege la informaci&oacute;n personal del USUARIO y es resguardado y gestionado por BANCANNABIS unicamente a trav&eacute;s de una
            contrase&ntilde;a designada por el usuario al momento de registrarse en la plataforma.
            En esta cuenta se registran todas las transacciones y env&iacute;os realizados a trav&eacute;s
            de la PLATAFORMA con motivo de compras y ventas
            de Bienes y / o Servicios ofrecidos por BANCANNABIS y sus ALIADOS con activos digitales compatibles con la tecnolog&itilde;a Blockchain de BANCANNABIS.
            En suma, la Cuenta contará con una gran gama de productos/servicios descentralizados (DeFi) operado por Contratos Inteligentes.</span>
          </p>
          <p>&nbsp;</p>
          <p><strong>CONTRATO INTELIGENTE: </strong><span style="font-weight: 400;">Un contrato inteligente es un programa
            inform&aacute;tico que facilita, asegura, hace cumplir y ejecuta acuerdos registrados entre dos o m&aacute;s
            partes (por ejemplo personas u organizaciones). Tienen como objetivo brindar una seguridad superior a la ley
            de contrato tradicional y reducir costos de transacci&oacute;n. La informaci&oacute;n que contiene el contrato
            es inmutable, ver&iacute;dica y a la vez en tiempo real, permitiendo ser consultada en cualquier momento y desde cualquier lugar.</span></p>
          <p>&nbsp;</p>
          <p><strong>PAGO EN L&Iacute;NEA:</strong><span style="font-weight: 400;"> Son las transacciones realizadas a
            trav&eacute;s de la PLATAFORMA u otras plataformas electr&oacute;nicas.</span></p>
          <p>&nbsp;</p>
          <p><strong>T&Eacute;RMINOS Y CONDICIONES O T&C</strong><span style="font-weight: 400;">: Son el conjunto de t&eacute;rminos,
            condiciones, obligaciones y pautas que rigen el uso de la PLATAFORMA y el intercambio de bienes y servicios por parte de los Usuarios.</span></p>
          <p>&nbsp;</p>
          <p><strong>BANCANNAPPS S.A.S</strong><span style="font-weight: 400;">:&nbsp; Es una sociedad comercial que provee de
            tecnolog&iacute;a blockchain para la innovaci&oacute;n y desarrollo de la industria del cannabis. BANCANNABIS, sus aliados o los USUARIOS COLABORADORES
            cultivan y transforman el cannabis con ayuda de los USUARIOS E-GROWER y otros modelos de participaci&oacute;n digital.
            Los bienes y servicios son ofertados y contratados a trav&eacute;s de la PLATAFORMA. BANCANNABIS no es una entidad
            financiera y por tanto no recauda dinero de curso legal de los USUARIOS.</span></p>
          <p>&nbsp;</p>
          <p><strong>BALANCE</strong><span style="font-weight: 400;">: Es el monto disponible a favor o en contra de las cuentas y / o subcuentas de
            los Aliados, USUARIOS, y/o BANCANNABIS para realizar intercambios digitales en la PLATAFORMA y los exchange digitales.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><strong>EXCHANGE: </strong><span style="font-weight: 400;">Los exchanges son compa&ntilde;&iacute;as o plataformas en l&iacute;nea dedicadas al
            comercio e intercambio de monedas digitales como bitcoin por otros activos, sean estos otras criptomonedas, tokens o dinero fiduciario de casi
            cualquier pa&iacute;s.</span></p>
          <p>&nbsp;</p>
          <p><strong>E-GROWER O CULTIVADOR DIGITAL: </strong><span style="font-weight: 400;">Son usuarios de la red que pueden ser empresas consumidoras o
            cultivadoras de cannabis, o a su vez personas naturales verificadas, y quienes se interesan en invertir en la explotaci&oacute;n comercial
            de los cultivos de cannabis.</span></p>
          <p>&nbsp;</p>
          <p><strong>MONEDA DIGITAL o TOKEN: </strong><span style="font-weight: 400;">Es</span><span style="font-weight: 400;"> la representaci&oacute;n digital de
            valor que puede ser objeto de comercio digital y cuyas funciones son la de constituir un medio de intercambio,
            y/o una unidad de cuenta, y/o una reserva de valor, que no tiene curso legal, ni se emiten, ni se encuentran garantizadas por ning&uacute;n
            pa&iacute;s o jurisdicci&oacute;n, ejemplos de estas son las de Bitcoin, Ethereum, Dash y monero entre otras.</span></p>
          <p>&nbsp;</p>
          <p><strong>TRANSACCI&Oacute;N:</strong> <span style="font-weight: 400;">Es el acuerdo aceptado por un Usuario para la compra y venta de un
            servicio establecidos a trav&eacute;s de la plataforma en el momento en que se lleve a cabo la operaci&oacute;n.</span></p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ul>
            <li><strong>5.&nbsp;<strong>ALCANCE</strong></strong></li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los presentes T&C constituyen un contrato v&aacute;lido y vinculante entre el Usuario que utilice el Sitio,
            por un lado, y el Administrador, por el otro. En caso de que el Usuario ingrese al Sitio y/o haga uso del Sitio se entender&aacute; que ha aceptado
            plenamente y sin reservas los presentes T&C.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los bienes y servicios ofertados en la PLATAFORMA est&aacute;n disponibles para personas que tengan capacidad para contratar.
            No podr&aacute;n hacer uso de los bienes y/o servicios de la PLATAFORMA las personas menores de edad o los Usuarios de BANCANNABIS
            que hayan sido suspendidos temporalmente o inhabilitados definitivamente.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Las personas jur&iacute;dicas o naturales que se inscriben como USUARIOS COLABORADORES,
            deben tener capacidad para contratar, tener los documentos mercantiles conforme a la ley&nbsp; y obligarse conforme a los
            t&eacute;rminos y condiciones de BANCANNABIS descritos en este documento.&nbsp;&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS aceptan que determinados servicios necesarios para el uso dela PLATAFORMA&nbsp;
            pueden ser prestados por empresas ALIADAS. En estos casos se establecer&aacute; un v&iacute;nculo contractual independiente
            entre los USUARIOS y dichas empresas para la provisi&oacute;n de esos servicios. Dichos servicios pueden estar sujetos a tarifas diferenciadas.
            El Usuario no podr&aacute; realizar reclamo alguno al Administrador derivado y/o vinculado con la prestaci&oacute;n
            de los servicios realizados por las Afiliadas.</span></p>
          <p>&nbsp;</p>
          <ol start="6">
            <li><strong>6.&nbsp;</strong><strong> DESCRIPCI&Oacute;N DEL SERVICIO:</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">La PLATAFORMA permite a los USUARIOS realizar operaciones de&nbsp; compra y venta de servicios
            relacionados con la cadena de suministros de cannabis sp, utilizando para ello los token con los que opera la PLATAFORMA.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><strong>6.1&nbsp; REGISTRO DE CUENTA EN LA PLATAFORMA DE BANCANNAPPS S.A.S</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Para hacer uso de la PLATAFORMA el USUARIO deber&aacute; proceder a registrarse completando el
            formulario de registro de la PLATAFORMA (el "Formulario"), al diligenciar y&nbsp; enviar el formulario el USUARIO declara bajo la
            gravedad de juramento la veracidad de la informaci&oacute;n sumistrada. El USUARIO deber&aacute; completar el Formulario con informaci&oacute;n
            fidedigna, real, completa, actualizada y verificable; en caso de ser necesario el USUARIO deber&aacute; actualizar el Formulario cuantas
            veces sea necesaria. BANCANNABIS se reserva el derecho de solicitar informaci&oacute;n y/o documentaci&oacute;n adicional a efectos
            de corroborar la informaci&oacute;n contenida en el Formulario.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Hasta que la informaci&oacute;n requerida para el uso de la PLATAFORMA est&eacute; completa,
            BANCANNABIS podr&aacute; limitar el uso de la PLATAFORMA al USUARIO. BANCANNABIS&nbsp; se reserva el derecho de suspender
            temporalmente y/o cancelar la cuenta de los USUARIOS que suministren informaci&oacute;n falsa o que no haya podido ser verificada.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">El USUARIO deber&aacute; acceder a la PLATAFORMA a trav&eacute;s de la cuenta de usuario que cree al
            momento de registrarse (la "cuenta"), all&iacute; deber&aacute; ingresar su direcci&oacute;n de correo electr&oacute;nico personal
            y una contrase&ntilde;a (alfanum&eacute;rica) de acceso. La Cuenta es estrictamente personal, &uacute;nica e intransferible;
            ser&aacute; asignada a un &uacute;nico USUARIO para su uso exclusivo y estar&aacute; estrictamente prohibida su venta y/o transmisi&oacute;n
            a terceras personas. Los USUARIOS s&oacute;lo podr&aacute;n tener una &uacute;nica cuenta en un momento dado, y no podr&aacute;n
            crear y/o utilizar otras cuentas que no sean su propia Cuenta. El USUARIO ser&aacute; responsable de mantener confidencial los
            datos de su Cuenta y en especial su contrase&ntilde;a.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En caso de que BANCANABIS detecte que la Cuenta es utilizada por un USUARIO que no es el titular de &eacute;sta,
            o que un USUARIO est&aacute; operando con m&aacute;s de una Cuenta, podr&aacute; suspender o bloquear definitivamente la/s Cuenta/s del USUARIO.
            En todos los casos, el Usuario ser&aacute; responsable por las operaciones efectuadas desde su Cuenta. El USUARIO deber&aacute; notificar de
            inmediato a BANCANNABIS respecto de cualquier uso no autorizado de su Cuenta, a efectos de que se tomen las medidas adecuadas a dichos efectos.</span></p>
          <p>&nbsp;</p>
          <p><strong>6.2 FUNCIONALIDADES Y PROCESOS DE LA PLATAFORMA</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">La PLATAFORMA&nbsp; permite a los USUARIOS comprar y vender servicios relacionados con la cadena
            de suministros de Cannabis con Monedas Digitales a trav&eacute;s de la PLATAFORMA El precio por el cual los USUARIOS pueden comprar
            y vender sus servicios variar&aacute; en funci&oacute;n del precio del mercado y de las decisiones de cada USUARIO. Ni BANCANNABIS
            ni la PLATAFORMA imponen a los USUARIOS un precio para la compra y/o venta de servicios relacionados con la cadena de suministros del Cannabis.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS comprenden que BANCANNABIS no compra ni vende Monedas Digitales y/o servicios de manera directa,
            ni participa activamente en la compra y venta de servicios Digitales a trav&eacute;s de la PLATAFORMA. La PLATAFORMA &uacute;nicamente habilita
            el acceso de los USUARIOS al mercado para la compra y venta de servicios.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS podr&aacute;n acceder a la plataforma de Crowdfunding para adquirir Tokens BCA por medio de un
            contrato inteligente y Ethereum &uacute;nicamente cuando se verifique su identidad.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Actualmente los USUARIOS podr&aacute;n apadrinar plantas por medio de la PLATAFORMA y contrato inteligente
            de Crowgrowing haciendo uso de los tokens BCA adquiridos durante las etapas del Crowdfunding a trav&eacute;s de ether..</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">los USUARIOS pueden vender o adquirir productos y servicios de la PLATAFORMA o tienda global.
            BANCANNABIS no es responsable de la calidad del producto o servicio que los USUARIOS de la red presten, pero si velar&aacute; por el
            cumplimiento de la normativa vigente para comercializar </span><em><span style="font-weight: 400;">cannabis sp
          </span></em><span style="font-weight: 400;">y brindar un servicio de calidad.</span></p>
          <p>&nbsp;</p>
          <p><strong>6.3 RIESGOS DEL SERVICIO</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Dado a la naturaleza de su comercializaci&oacute;n, y del car&aacute;cter fluctuante del precio de las
            Monedas Digitales en el mercado, BANCANNABIS no se har&aacute; responsable por la p&eacute;rdida de dinero en la que incurran los USUARIOS como consecuencia del aumento o disminuci&oacute;n del valor de las Monedas Digitales.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">A diferencia de las Monedas de Curso Legal, las Monedas Digitales est&aacute;n respaldadas por los protocolos
            y tecnolog&iacute;as que los sustentan, y en la confianza generada en el p&uacute;blico en general. Los USUARIOS manifiestan conocer que las Monedas Digitales no han sido reconocidas como monedas o medios de pago por diversas autoridades monetarias, y que el tratamiento legal de las Monedas Digitales difiere en distintas jurisdicciones. En virtud de lo anterior, los USUARIOS asumen y son responsables en su totalidad por el riesgo de operar con Monedas Digitales, liberando de toda responsabilidad o consecuencia emanada de ello a BANCANNABIS y a sus ALIADOS, sus operadores, accionistas, socios, administradores, gerentes, trabajadores y titulares.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS no tiene autoridad ni control sobre los protocolos de software que gobiernan las operaciones en las Monedas Digitales que operan en la PLATAFORMA. En general, estos protocolos son de c&oacute;digo abierto y cualquier persona puede usarlos, copiarlos, modificarlos y distribuirlos.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">El Usuario reconoce y acepta que (i) BANCANNABIS no es responsable por el funcionamiento de los protocolos subyacentes y no garantiza su funcionalidad, seguridad o disponibilidad y (ii) los protocolos est&aacute;n sujetos a cambios repentinos en sus reglas operativas (es el caso de las eventuales bifurcaciones o &ldquo;forks&rdquo;). Tales bifurcaciones pueden afectar materialmente el valor, funcionalidad y/o denominaci&oacute;n de las Monedas Digitales almacenadas en la PLATAFORMA.</span></p>
          <p>&nbsp;</p>
          <p><strong>6.4&nbsp; TARIFAS Y COMISIONES DEL SERVICIO</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS no cobrar&aacute; a los USUARIOS monto alguno por registrarse en el Sitio.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS&nbsp; cobrar&aacute; a los Usuarios despu&eacute;s de la participaci&oacute;n privada una comisi&oacute;n por cada Transacci&oacute;n realizada a trav&eacute;s de la PLATAFORMA&nbsp; que se expresar&aacute; como un porcentaje sobre el monto de la operaci&oacute;n de compra o de venta, seg&uacute;n corresponda. La comisi&oacute;n ser&aacute; expresada en el monto de Tokens BCA que corresponda seg&uacute;n la operaci&oacute;n de que se trate. Las comisiones ser&aacute;n deducidas de la Cuenta del Usuario al momento de ejecutarse la orden. </span><span style="font-weight: 400;">BANCANNABIS cobrar&aacute; una comisi&oacute;n entre el 0.5% y el 0.65% del monto operado, por cada operaci&oacute;n de compra y por cada operaci&oacute;n de venta que realice el Usuario a trav&eacute;s de la PLATAFORMA.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS cobrar&aacute; a los USUARIOS al momento de realizar un retiro de fondos una comisi&oacute;n del 1% sobre el monto que se retire, el cual ser&aacute; descontado del monto que se pretenda retirar de la PLATAFORMA..</span></p>
          <p>&nbsp;</p>
          <p><strong>6.5 USOS DE COOKIES</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS conocen y aceptan que BANCANNABIS podr&aacute; utilizar en la PLATAFORMA sistemas y/o servicios de seguimiento y/o tecnolog&iacute;as similares o que cumplan con una funci&oacute;n similar, tales como Cookies. Las Cookies son peque&ntilde;os archivos que se instalan en el sitio de almacenamiento de la computadora o dispositivo utilizado por el USUARIO, con una duraci&oacute;n limitada en el tiempo que ayudan a personalizar los servicios. Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demograf&iacute;a de quienes visitan o utilizan la PLATAFORMA y de esa forma, comprender mejor sus necesidades e intereses y brindar un mejor servicio o proveer informaci&oacute;n relacionada.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS tambi&eacute;n puede utilizar la informaci&oacute;n obtenida por intermedio de las Cookies para analizar las p&aacute;ginas navegadas por el USUARIO, as&iacute; como sus b&uacute;squedas realizadas, para mejorar y/o personalizar iniciativas comerciales y promocionales, realizar noticias, publicidad o promociones, banners de inter&eacute;s y perfeccionar su oferta de contenidos; tambi&eacute;n podr&aacute; utilizar Cookies para contabilizar y corroborar los registros y niveles de validaci&oacute;n, as&iacute; como para promover y hacer cumplir las reglas y seguridad de la PLATAFORMA. Se establece que la instalaci&oacute;n, permanencia y existencia de las cookies en la computadora o dispositivo del USUARIO depende de su exclusiva voluntad y puede ser eliminada de su computadora o dispositivo cuando &eacute;l as&iacute; lo desee.</span></p>
          <p>&nbsp;</p>
          <!-- <p><strong>6.2 E-GROWER:&nbsp;</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.2.1 USO DE USUARIO E-GROWER:</strong></p>
          <p><strong>6.2.2 LOS USUARIOS E-GROWER PUEDEN:</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.3 USUARIO COLABORADOR:</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.3.1 USO DE USUARIO COLABORADOR:</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.3.2 LOS USUARIO COLABORADORES PUEDEN:</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.4 USUARIO COMUNIDAD:</strong></p>
          <p><strong>6.4.1 USO DE USUARIO COMUNIDAD:</strong></p>
          <p>&nbsp;</p>
          <p><strong>6.4.2 LOS USUARIOS COMUNIDAD PUEDEN:</strong></p> -->
          <p>&nbsp;</p>
          <ol start="7">
            <li><strong> OBLIGACIONE Y PROHIBICIONES&nbsp; DEL USUARIO&nbsp;</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>7.1 OBLIGACIONES USUARIO E-GROWER</strong></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">Proporcionar informaci&oacute;n ver&iacute;dica y verificable al momento de crear una cuenta de usuario en la PLATAFORMA.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">En virtud de la legislaci&oacute;n vigente en materia de prevenci&oacute;n del lavado de activos y de la financiaci&oacute;n del terrorismo, el USUARIO, en el momento de contratar los servicios de BANCANNABIS, se obliga a (i) facilitar, de forma completa y veraz, cuanta informaci&oacute;n sea necesaria y le sea requerida a estos efectos, (ii) autoriza expresamente a BANCANNABIS a realizar las actuaciones de verificaci&oacute;n que, al respecto, considere oportunas y, sin perjuicio de lo anterior, (iii) manifiesta que el origen de los recursos que emplea para abonar el pago por los servicios de BANCANNABIS, es l&iacute;cito y no contraviene lo previsto en legislaci&oacute;n vigente en materia de prevenci&oacute;n del lavado de activos y de la financiaci&oacute;n del terrorismo.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">Dar aviso inmediato a BANCANNABIS a trav&eacute;s del correo electr&oacute;nico </span><a href="mailto:contac@bancannabis.org"><span style="font-weight: 400;">contac@bancannabis.org</span></a><span style="font-weight: 400;">&nbsp; de cualquier circunstancia que amenace y/o ponga en riesgo la integridad de los datos almacenados en la PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">Tener claro y aceptar la informaci&oacute;n de los productos por adquirir.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">Adquirir el Token BCA para intercambiar en la PLATAFORMA por los bienes y/o servicios ofertados por BANCANNABIS y sus ALIADOS.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">Autorizar a BANCANNABIS el tratamiento a sus datos personales de conformidad con la ley y nuestras pol&iacute;ticas de privacidad y tratamiento de datos personales. Usted puede tener acceso a nuestra pol&iacute;tica de privacidad a trav&eacute;s de la p&aacute;gina ___________</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              g).&nbsp;<span style="font-weight: 400;">Utilizar la PLATAFORMA de BANCANNABIS de acuerdo a los presentes t&eacute;rminos y condiciones de uso ofrecidos por BANCANNABIS&nbsp; y aquellas modificaciones que se agreguen en el momento que los mismos sean actualizados.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              h).&nbsp;<span style="font-weight: 400;">Consultar de manera permanente el texto de T&eacute;rminos y Condiciones de la PLATAFORMA de BANCANNABIS para conocer los cambios que se presenten en el mismo</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              i).&nbsp;<span style="font-weight: 400;">Hacer uso personal de la dApp y abstenerse de revender y obtener ingresos con motivo de la reventa y / o comercializaci&oacute;n de los Bienes y / o Servicios de los Aliados y / o Empresas Asociadas que hayan sido adquiridos a t&iacute;tulo personal.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              j).&nbsp;<span style="font-weight: 400;">&nbsp;Descargar actualizaciones de dApp de forma permanente.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              k).&nbsp;<span style="font-weight: 400;">Siga las recomendaciones de BANCANNABIS con respecto a las pr&aacute;cticas adecuadas en el uso de la DApp.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              l).&nbsp;<span style="font-weight: 400;">Cambie peri&oacute;dicamente la contrase&ntilde;a de seguridad para el acceso y uso de la DApp, Si el usuario pierde su contrase&ntilde;a no se podr&aacute; recuperar los fondos.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              m).&nbsp;<span style="font-weight: 400;">Informar de inmediato a BANCANNABIS la p&eacute;rdida y / o robo del tel&eacute;fono y / o dispositivo m&oacute;vil desde el que se utiliza la DApp.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              n).&nbsp;<span style="font-weight: 400;">Informe de inmediato a BANCANNABIS sobre cualquier transacci&oacute;n no autorizada y / o fraudulenta a trav&eacute;s de la DApp de la que tenga conocimiento o deber&iacute;a haber tenido conocimiento.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              o).&nbsp;<span style="font-weight: 400;">Seguir la trazabilidad de las plantas compradas para su cultivo digital.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>7.2 OBLIGACIONES USUARIO COLABORADOR</strong></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">Debe tener la capacidad legal y las autorizaciones necesarias para realizar la actividad econ&oacute;mica, ya sea comercializando semillas e insumos para el cultivo de cannabis, desarrollando proyectos de cultivo de cannabis o transformando la materia prima en derivados del cannabis.&nbsp;</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">El USUARIO </span><strong>COLABORADOR </strong><span style="font-weight: 400;">deber&aacute; remitir a BANCANNABIS la documentaci&oacute;n pertinente para efectuar transacciones a trav&eacute;s de la PLATAFORMA de BANCANNABIS, incluida la documentaci&oacute;n corporativa y los documentos del activo o bien, estos podr&aacute;n corresponder de forma enunciativa y no limitativa a: especificaciones t&eacute;cnicas, cotizaci&oacute;n, facturaci&oacute;n y cualquier otra informaci&oacute;n que a juicio de BANCANNABIS resulte necesaria para efectuar la Transacci&oacute;n.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">El USUARIO COLABORADOR deber&aacute; remitir a BANCANNABIS (a) certificado de existencia y representaci&oacute;n legal, (b) estatutos de la sociedad titular de la licencia y del cultivo y Usuario Emprendedor vinculado a BANCANNABIS (c) licencia de autorizaci&oacute;n de producci&oacute;n de Cannabis, (d) plan de negocio del proyecto productivo y (e) pruebas de evaluaci&oacute;n agron&oacute;mica. BANCANNABIS se reserva el derecho de solicitar informaci&oacute;n adicional o de aceptar o rechazar usuarios teniendo en cuenta la informaci&oacute;n aportada.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">El USUARIO COLABORADOR deber&aacute; facilitar la inspecci&oacute;n t&eacute;cnica por parte de BANCANNABIS a efectos de identificar las variables t&eacute;cnicas del cultivo del USUARIO COLABORADOR para mantener actualizada la PLATAFORMA y a los E-GROWER.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">El USUARIO COLABORADOR deber&aacute; informar, con un t&eacute;rmino prudente de anterioridad de la existencia de una oferta calificada a efectos de que </span><span style="font-weight: 400;">BANCANNABIS </span><span style="font-weight: 400;">inicie las labores tendientes conseguir una Transacci&oacute;n.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">Por la naturaleza de la actividad comercial de BANCANNABIS, este no es responsable por el efectivo cumplimiento de las obligaciones fiscales o impositivas establecidas por la ley vigente que le sean aplicables al Usuario Emprendedor las cuales declara conocer cada una de las partes al realizar la Transacci&oacute;n.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>7.3 PROHIBICIONES DE LOS USUARIOS EN GENERAL:</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los USUARIOS registrados en la PLATAFORMA de BANCANNABIS no podr&aacute;n:</span></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">Mantener ning&uacute;n tipo de comunicaci&oacute;n directa por e-mail, o por cualquier otro medio (incluso redes sociales) durante la oferta del bien con ninguno de los Usuarios que participan en la misma, salvo mediante el uso de las herramientas expresamente destinadas al efecto en la PLATAFORMA. Esta restricci&oacute;n ser&aacute; igualmente aplicable a la negociaci&oacute;n de las condiciones de los contratos que formalizan la Transacci&oacute;n.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">Insultar o agredir a otros USUARIOS.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">Utilizar su reputaci&oacute;n, calificaciones o comentarios recibidos en la PLATAFORMA o en cualquier &aacute;mbito fuera de la PLATAFORMA.</span>
            </li>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">Crear dos cuentos con los mismos datos de usuario.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">Crear identidades falsas, actuar en nombre de terceros sin el poder o legitimaci&oacute;n, suplantando su identidad.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">Introducir datos falsos o marcas y productos ficticios por lo cual estos deben ser exactos y veraces.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              g).&nbsp;<span style="font-weight: 400;">Infringir derechos de terceros, en especial de propiedad industrial e intelectual.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              h).&nbsp;<span style="font-weight: 400;">Faltar a la verdad, falsificando documentos, firmas, infringir leyes tanto del &aacute;mbito nacional como internacional.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              i).&nbsp;<span style="font-weight: 400;">Manipular o alterar las herramientas de la plataforma para comercializar con terceros en forma distinta a la se&ntilde;alada en los presentes T&Eacute;RMINOS Y CONDICIONES.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              j).&nbsp;<span style="font-weight: 400;">Utilizar la plataforma, para fines distintos a los establecidos en los T&C.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              k).&nbsp;<span style="font-weight: 400;">Utilizar la plataforma BANCANNABIS con el prop&oacute;sito de almacenar im&aacute;genes personales, videos propios y/o cualquier otro tipo de informaci&oacute;n que no corresponda estrictamente a lo requerido para llevar a cabo la sistematizaci&oacute;n, organizaci&oacute;n y catalogaci&oacute;n de la informaci&oacute;n financiera del usuario.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              l).&nbsp;<span style="font-weight: 400;">Utilizar la plataforma BANCANNABIS con el prop&oacute;sito de perpetrar un acto criminal o contrario a la legislaci&oacute;n vigente.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              m).&nbsp;<span style="font-weight: 400;">Utilizar la plataforma para transferir, comunicar, reproducir, poner a disposici&oacute;n y/o retransmitir contenidos que resulten discriminatorios, ofensivos, de contenido sexual y otros que puedan afectar, ofender y/o agredir a los destinatarios del contenido y/o a la sociedad.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              n).&nbsp;<span style="font-weight: 400;">Vender, compartir, copiar, redistribuir, revender, transferir, licenciar y en general realizar cualquier acto de explotaci&oacute;n o disposici&oacute;n de los derechos sobre la PLATAFORMA, sin haber obtenido previamente una autorizaci&oacute;n expresa y escrita de BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              o).&nbsp;<span style="font-weight: 400;">Utilizar la plataforma con el prop&oacute;sito de infringir la propiedad intelectual de terceros y/o de BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              p).&nbsp;<span style="font-weight: 400;">Realizar ingenier&iacute;a inversa respecto de la plataforma con el prop&oacute;sito de acceder a su c&oacute;digo fuente, salvo los casos en que la normatividad vigente se lo permita expresamente y, aun as&iacute;, sin que pueda utilizar la informaci&oacute;n accedida para realizar copias, obras derivadas o aplicaciones que puedan afectar o no la seguridad de la PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              q).&nbsp;<span style="font-weight: 400;">No est&aacute; permitida ninguna acci&oacute;n o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de BANCANNABIS o de la PLATAFORMA, de propiedad, administrado y operado por BANCANNABIS, como en las ofertas, descripciones, cuentas o bases de datos de BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              r).&nbsp;<span style="font-weight: 400;">Utilizar los signos distintivos de BANCANNABIS y en general, la imagen corporativa de BANCANNABIS sin obtener previamente la autorizaci&oacute;n expresa y escrita de sus titulares.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              s).&nbsp;<span style="font-weight: 400;">Realizar cualquier tipo de actividad, maniobra o acci&oacute;n que logre o tenga por efecto atentar en contra la estabilidad de la&nbsp; PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Este tipo de actividades ser&aacute; investigado por BANCANNABIS y el infractor podr&aacute; ser sancionado con la suspensi&oacute;n o cancelaci&oacute;n de la oferta e incluso de su registro como Usuario de BANCANNABIS y/o de cualquier otra forma que BANCANNABIS estime pertinente, sin perjuicio de las acciones legales a que pueda dar lugar por la configuraci&oacute;n de delitos o contravenciones o los perjuicios civiles que pueda causar a los Usuarios oferentes.</span></p>
          <p>&nbsp;</p>
          <ol start="8">
            <li>8.&nbsp;<strong> R&Eacute;GIMEN SANCIONATORIO GENERAL</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">La inhabilitaci&oacute;n, suspensi&oacute;n o bloqueo de cuentas de las cuentas de los USUARIOS registrados en la PLATAFORMA por parte de BANCANNABIS, se har&aacute; respetando el debido proceso de descargos, verificaci&oacute;n y apelaci&oacute;n por parte del posible infractor o acusado de infractor. Nosotros como empresa nos comprometemos a confirmar el uso de la cuenta, corroboraci&oacute;n de los datos proporcionados en el registro y / u otra informaci&oacute;n que se requiera antes de deshabilitar, suspender o bloquear permanentemente la cuenta.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS podr&aacute; advertir, suspender en forma temporal, deshabilitar o bloquear definitivamente, aplicar una sanci&oacute;n que impacte negativamente en la reputaci&oacute;n la cuenta de un USUARIO registrado o una publicaci&oacute;n en la PLATAFORMA; o no prestar sus servicios cuando se presente alguno de los siguientes casos:</span></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">Si incumpliera sus compromisos como USUARIO, (i) se le impondr&aacute; una sanci&oacute;n que impacte su reputaci&oacute;n; si reitera la conducta (ii) se le suspender&aacute; la cuenta de USUARIO por una (1) semana; si incurre nuevamente en la conducta, (iii) se le deshabilitar&aacute; la cuenta por un (1) mes, y finalmente, si reincide en la conducta&nbsp; se bloqueara definitivamente la cuenta de USUARIO.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">Si se encuentra culpable de incurrir en conductas o actos dolosos o fraudulentos, se bloqueara definitivamente la cuenta de usuario.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">Si no pudiera verificarse la identidad del USUARIO o cualquier informaci&oacute;n proporcionada por el mismo fuere err&oacute;nea se proceder&aacute; a la suspensi&oacute;n temporal hasta que se verifique o se bloquee definitivamente la cuenta de USUARIO.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">En el caso del bloqueo de un USUARIO, todos los art&iacute;culos que tuviera publicados en la Plataforma ser&aacute;n removidos. Tambi&eacute;n se remover&aacute;n del sistema las ofertas de compra o venta de los bienes y/o servicios ofrecidos en la PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">En caso de cuentas duplicadas se bloquer&aacute; definitivamente al USUARIO.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">El registro de un menor de edad&nbsp; (menores de 18 a&ntilde;os en Colombia) tiene como resultado la eliminaci&oacute;n de su cuenta de forma inmediata.&nbsp;&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              g).&nbsp;<span style="font-weight: 400;">Las operaciones sospechosas de BANCANNABIS se acogen a una pol&iacute;tica de Sistema de Gesti&oacute;n de Riesgos para Lavado de Activos y Financiamiento del Terrorismo SARLAFT . Por lo tanto, de acuerdo con nuestras pol&iacute;ticas de SARLAFT , procederemos a verificar y consultar la informaci&oacute;n de nuestros USUARIOS al momento de registrarse con nosotros.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              h).&nbsp;<span style="font-weight: 400;">Si se sospecha que un usuario infringe nuestras pol&iacute;ticas de SARLAFT o da&ntilde;a a otros miembros de nuestra comunidad con operaciones sospechosas,BANCANNABIS tiene derecho a suspender, deshabilitar o bloquear al USUARIO que se encuentre culpable de la infracci&oacute;n.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              i).&nbsp;<span style="font-weight: 400;">En caso de no obtener la informaci&oacute;n que fuera necesaria para verificar el lavado de activos y de la financiaci&oacute;n del terrorismo, BANCANNABIS se ver&aacute; imposibilitado de prestar servicios al USUARIO, sin que ello pueda comportar ning&uacute;n tipo de responsabilidad para BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              j).&nbsp;<span style="font-weight: 400;">La reiteraci&oacute;n de conductas&nbsp; prohibidas por parte de los USUARIOS que no cumplan con los T&C de BANCANNABIS se les aplicara el siguiente procedimiento; (i) se les notificar&aacute; dicha infracci&oacute;n, en caso de hacer caso omiso a la advertencia, (ii) se suspender&aacute; la cuenta del USUARIO por una (1) semana, y en caso de reincidir en conductas prohibidas, (iii) se deshabilitar&aacute; la cuenta de USUARIO por un (1) mes y finalmente, si el USUARIO vuelve a cometer&nbsp; conducta prohibida por cuarta vez, (iv) se bloqueara al usuario y se eliminar&aacute; la cuenta de USUARIO de la PLATAFORMA DE BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              k).&nbsp;<span style="font-weight: 400;">Cuentas suspendidas: el USUARIO no podr&aacute; acceder al panel de usuario hasta que se levante la suspensi&oacute;n de la cuenta.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              l).&nbsp;<span style="font-weight: 400;">Cuentas deshabilitadas: el USUARIO puede acceder a su cuenta pero no puede realizar acciones en la PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              m).&nbsp;<span style="font-weight: 400;">Cuentas bloqueadas: el USUARIO no puede hacer uso de la PLATAFORMA hasta que se cumpla la pena impuesta por su infracci&oacute;n.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              n).&nbsp;<span style="font-weight: 400;">Reactivaci&oacute;n de cuenta: Las cuentas de USUARIOS se reactivar&aacute;n cuando se realice el debido proceso junto con sus respectivas validaciones y verificaciones de las causales de suspensi&oacute;n, inhabilitaci&oacute;n o bloqueo por parte de BANCANNABIS o las autoridades judiciales.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Estas medidas o acciones tomadas por BANCANNABIS no inhabilitan a BANCANNAIBIS de iniciar otras medidas a las que pueda tener derecho BANCANNABIS por las posibles infracciones cometidas por los USUARIOS de la PLATAFORMA.</span></p>
          <p><br><br></p>
          <ol start="9">
            <li>9.&nbsp;<strong> OBLIGACIONES DE E-GROW TECNOLOGY SERVICES S.A.S</strong></li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">La obligaci&oacute;n de comunicar a la Unidad de Informaci&oacute;n y An&aacute;lisis Financiero cualquier hecho u operaci&oacute;n respecto de los cuales exista indicio o certeza de que est&aacute; relacionado con el lavado de activos o la financiaci&oacute;n del terrorismo, debiendo abstenerse de ejecutar cualquier operaci&oacute;n respecto de la que se pongan de manifiesto tales circunstancias. BANCANNABIS no ser&aacute; responsable ante el USUARIO de los da&ntilde;os y perjuicios que este pueda sufrir como consecuencia del cumplimiento, por parte de BANCANNABIS de dichas obligaciones legales.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">Asegurar el cumplimiento de los est&aacute;ndares de seguridad sobre la informaci&oacute;n de los USUARIOS y sus transacciones.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">Mantener a disposici&oacute;n del USUARIO la PLATAFORMA para el intercambio electr&oacute;nicos oportuno basado en tecnolog&iacute;a blockchain que permite la compra de Bienes y/o Servicios de BANCANNABIS y sus ALIADOS o USUARIOS COLABORADORES.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">Dar aviso oportuno&nbsp; al USUARIO de cambios y/o modificaciones de los T&eacute;rminos y Condiciones por los canales de informaci&oacute;n de la PLATAFORMA y/o a trav&eacute;s de los medios de contacto suministrados por el USUARIO para este fin.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">Modificar los T&eacute;rminos y Condiciones en procura del bienestar de la comunidad de BANCANNABIS.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">Verificar las transacciones de intercambio realizadas por medio de la PLATAFORMA de BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              g).&nbsp;<span style="font-weight: 400;">Realizar un mantenimiento constante en el software y hardware que soporta la PLATAFORMA.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              h).&nbsp;<span style="font-weight: 400;">En raz&oacute;n de que BANCANNABIS no pretende captar recursos del p&uacute;blico de forma masiva y habitual, BANCANNABIS no utilizar&aacute;, dispondr&aacute; ni manipular&aacute; de ninguna forma los saldos del USUARIO, sin la orden expresa del mismo o una autoridad competente.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              i).&nbsp;<span style="font-weight: 400;">El USUARIO no recibir&aacute; intereses por los saldos depositados en la cartera.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              j).&nbsp;<span style="font-weight: 400;">Asegurar la trazabilidad de los productos y mantener la PLATAFORMA actualizada con el estado vegetativo de las plantas cultivadas por los USUARIOS E-GROWER.&nbsp;&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              k).&nbsp;<span style="font-weight: 400;">Cumplir con las buenas pr&aacute;cticas y la legislaci&oacute;n vigente para el cultivo del cannabis y posteriores transformaci&oacute;n de la materia prima en derivados del cannabis.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              l).&nbsp;<span style="font-weight: 400;">Asegurar el cumplimiento de las buenas pr&aacute;cticas y la legislaci&oacute;n vigente de las empresas ALIADAS y/o de los USUARIOS COLABORADORES.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              m).&nbsp;<span style="font-weight: 400;">BANCANNABIS no se hace responsable por las p&eacute;rdidas que pueda tener un USUARIO producto de la fuerza mayor, el caso fortuito, el hecho del tercero y/o el hecho de la v&iacute;ctima. En consecuencia, no se hace responsable de cualquier da&ntilde;o, reclamaci&oacute;n o p&eacute;rdida que surja o que el USUARIO pueda sufrir como consecuencia de cualquier retraso en las transacciones autorizadas por el USUARIO.</span>
            </li>
            <li style="font-weight: 400;">
              n).&nbsp;<span style="font-weight: 400;">Rendir cuentas a los USUARIOS E-GROWER al finalizar cada cosecha del cultivo digital.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start="10">
            <li>10.&nbsp;<strong> MARCAS COMERCIALES / DERECHOS DE PROPIEDAD</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Las marcas comerciales, logotipos, marcas de servicio y nombres comerciales (en conjunto, las "Marcas comerciales") que se muestran en este sitio web no se pueden utilizar sin nuestro consentimiento expreso por escrito, sin perjuicio de las marcas comerciales que no son de nuestra propiedad, si las hay, son propiedad de sus respectivos due&ntilde;os. y el uso est&aacute; sujeto al consentimiento de dicho propietario. Este sitio web tambi&eacute;n puede contener o hacer referencia a patentes, informaci&oacute;n patentada, tecnolog&iacute;as, productos, procesos u otros derechos de propiedad ("IP") de BANCANNABIS&nbsp; y / o de otras partes. No se le otorga ni se le confiere ninguna licencia o derecho sobre dicha propiedad intelectual.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS se reserva todos los derechos de propiedad intelectual que se derivan de la PLATAFORMA la estructura de las bases de datos, dise&ntilde;o de p&aacute;gina y dem&aacute;s que han sido creadas y los de las que se desarrollen para su uso o explotaci&oacute;n. Lo anterior, sin perjuicio del respeto que har&aacute; BANCANNABIS de los derechos de propiedad intelectual correspondan a terceros.</span></p>
          <p>&nbsp;</p>
          <ol start="11">
            <li>11.&nbsp;<strong> CL&Aacute;USULA DE RESPONSABILIDAD E INDEMNIDAD DE BANCANNABIS CON SUS USUARIOS</strong></li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">BANCANNABIS responder&aacute; ante el USUARIO, de los da&ntilde;os y perjuicios que se le hubiesen causado por causas imputables a BANCANNABIS o a sus profesionales o empleados como consecuencia de dolo o culpa grave de cualquiera de ellos. Fuera de tales casos, la responsabilidad de BANCANNABIS tendr&aacute; el alcance que se determine espec&iacute;ficamente para cada Servicio de conformidad con el alcance definido en la PLATAFORMA as&iacute; como el alcance que se incluye a continuaci&oacute;n en estas Condiciones de Uso.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">En ning&uacute;n caso BANCANNABIS ser&aacute; responsable de los da&ntilde;os derivados, o causados, en todo o en parte, como consecuencia de la falsedad, el ocultamiento o cualquier otra conducta del USUARIO que fuera dolosa o negligente, o no realizada conforme a los principios de la buena fe, o de incumplimientos a estas Condiciones de Uso o que se produzcan por causas que est&aacute;n fuera de su control razonable.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">La responsabilidad de BANCANNABIS se limitar&aacute; a los da&ntilde;os directos (con exclusi&oacute;n, por tanto, del lucro cesante, de la p&eacute;rdida de negocio o del da&ntilde;o reputacional) efectivamente causados al USUARIO. El USUARIO conoce y acepta que al realizar operaciones con otros USUARIOS o terceros lo hace bajo su propio riesgo.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">En caso de que uno o m&aacute;s USUARIOS o alg&uacute;n tercero inicien cualquier tipo de reclamo o acciones legales contra otro u otros USUARIOS, todos y cada uno de los USUARIOS involucrados en dichos reclamos o acciones eximen de toda responsabilidad a BANCANNABIS y a sus directores, gerentes, empleados, agentes, operarios, representantes y apoderados. Los Usuarios tienen un plazo de diez d&iacute;as desde la compra para iniciar un reclamo contra otro u otros USUARIOS. Una vez vencido este plazo, no podr&aacute;n iniciar un reclamo desde la PLATAFORMA. Lo anterior, sin perjuicio de las acciones legales con las que cuente.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">La responsabilidad de BANCANNABIS frente al USUARIO en relaci&oacute;n con los servicios prestados est&aacute; condicionada a que el USUARIO remita reclamaci&oacute;n por escrito, determinando con suficiente detalle la naturaleza de la reclamaci&oacute;n y el importe reclamado, en un plazo no superior a tres (3) a&ntilde;os a contar desde la finalizaci&oacute;n de la prestaci&oacute;n de los servicios, salvo en caso de dolo en que ser&aacute; de aplicaci&oacute;n el plazo de prescripci&oacute;n legalmente establecido.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              f).&nbsp;<span style="font-weight: 400;">La eventual responsabilidad de BANCANNABIS tendr&aacute; lugar &uacute;nicamente frente al USUARIO. BANCANNABIS no ser&aacute; responsable de los da&ntilde;os que puedan ocasionarse a terceros como consecuencia del uso que el USUARIO pueda hacer de los servicios fuera del destino propio de los mismos.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              g).&nbsp;<span style="font-weight: 400;">En ning&uacute;n caso se entender&aacute; excluida la responsabilidad de BANCANNABIS por acciones u omisiones cuya responsabilidad no pueda excluirse ni reducirse seg&uacute;n la normativa aplicable.</span>
            </li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <ol>
            <li style="font-weight: 400;">
              h).&nbsp;<span style="font-weight: 400;">Sin perjuicio de la generalidad de lo anterior, BANCANNABIS no se responsabiliza por cualquier da&ntilde;o, perjuicio o p&eacute;rdida al USUARIO causados por fallas en la PLATAFORMA, en el servidor o en Internet.&nbsp;</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              i).&nbsp;<span style="font-weight: 400;">BANCANNABIS tampoco ser&aacute; responsable por cualquier virus que pudiera infectar el equipo del USUARIO como consecuencia del acceso, uso o examen de su sitio web o a ra&iacute;z de cualquier transferencia de datos, archivos, im&aacute;genes, textos, o audio contenidos en el mismo. Los USUARIOS no podr&aacute;n imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades t&eacute;cnicas o fallas en los sistemas o en Internet.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              j).&nbsp;<span style="font-weight: 400;">BANCANNABIS no garantiza el acceso y uso continuado o ininterrumpido de la PLATAFORMA. El sistema puede eventualmente no estar disponible debido a dificultades t&eacute;cnicas o fallas de Internet, o por cualquier otra circunstancia ajena a BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p><strong>11.1 EXCLUSI&Oacute;N DE RESPONSABILIDAD.</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Al usar la PLATAFORMA de BANCANNABIS usted como USUARIO acepta que BANCANNABIS no asume ning&uacute;n tipo de responsabilidad contractual o extracontractual, civil, comercial o penal, por concepto de afectaciones a su informaci&oacute;n empresarial, sus bienes y los de quienes tiene a cargo, cuando dichas afectaciones se deriven de la existencia de fallas en la disponibilidad del servicio no imputables a BANCANNABIS, la p&eacute;rdida de la informaci&oacute;n por mal uso de la plataforma, la filtraci&oacute;n de la informaci&oacute;n por acceso indebido de terceros que se derive de la exposici&oacute;n de los datos de acceso por parte de los USUARIOS.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Al usar la PLATAFORMA el usuario declara expresamente que conoce los riesgos de uso de la misma y que ha le&iacute;do los presentes t&eacute;rminos y condiciones y luego de hacerlo, han tomado las medidas de seguridad necesarias para proteger los bienes y la informaci&oacute;n de la cual son titulares.</span></p>
          <p>&nbsp;</p>
          <ol start="12">
            <li>12.&nbsp;<strong> TRATAMIENTO DE DATOS - COMPROMISO DE CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACI&Oacute;N.</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En principio, BANCANNABIS no utilizar&aacute; la informaci&oacute;n empresarial y los datos personales almacenados en sus bases de datos por parte del USUARIO para fines distintos al objeto de la PLATAFORMA de BANCANNABIS.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Sin embargo, con el uso que el USUARIO hace de la PLATAFORMA, autoriza expresamente a BANCANNABIS para utilizar los datos almacenados en sus bases de datos con el fin de generar estad&iacute;sticas an&oacute;nimas de la utilizaci&oacute;n de la PLATAFORMA y/o remitirles informaci&oacute;n comercial respecto de los servicios ofrecidos, promociones y dem&aacute;s que correspondan por parte de BANCANNABIS, sus ALIADOS y/o USUARIOS COLABORADORES. Cuando se realizan trabajos estad&iacute;sticos, los datos son utilizados sin relacionarlos con su titular, es decir, son an&oacute;nimos para efecto de la realizaci&oacute;n de mediciones de las preferencias de los usuarios en general. Las mediciones realizadas y los resultados de las mismas podr&aacute;n ser comercializados posteriormente por parte de la BANCANNABIS sin relacionar los datos directamente con ninguno de los USUARIOS.</span></p>
          <p><br><br></p>
          <p><span style="font-weight: 400;">BANCANNABIS solamente tolerar&aacute; el acceso a la informaci&oacute;n empresarial y los datos personales almacenados, a quien los ha ingresado. As&iacute; mismo, se garantizar&aacute; el acceso a las autoridades judiciales y administrativas cuando con fundamento en la ley est&eacute;n en capacidad de obligar a BANCANNABIS a cumplir tal exigencia.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS desplegar&aacute; todos los medios que se encuentren a su alcance y que se deriven de las obligaciones previstas en la ley para procurar proteger los datos personales y dem&aacute;s informaci&oacute;n almacenada en sus bases de datos.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Los datos personales que BANCANNABIS recaude en desarrollo de la prestaci&oacute;n de sus servicios se encuentran sometidos a las pol&iacute;ticas de protecci&oacute;n de datos personales de la PLATAFORMA y puede acceder a ellas en la p&aacute;gina ____________. En caso que usted tenga una petici&oacute;n, queja, reclamo o desee ejercer cualquiera de los derechos que se derivan del tratamiento de datos personales, por favor remita un correo a contac@bancannabis.org.</span></p>
          <p>&nbsp;</p>
          <p><strong>12.1 AUTORIZACI&Oacute;N PARA ADMINISTRAR INFORMACI&Oacute;N DEL USUARIO</strong></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Hacer uso de la PLATAFORMA, registrarse en la misma, o acceder a los servicios de BANCANNABIS a trav&eacute;s de nuestro sitio web www.e-groweed.com, autoriza a BANCANNABIS para que trate los datos del USUARIO conforme a las finalidades que se describen a continuaci&oacute;n:</span></p>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              a).&nbsp;<span style="font-weight: 400;">Enviar mensajes dentro de la PLATAFORMA y/o en mi bandeja de entrada de mi cuenta de usuario dentro de BANCANNABIS; para contactarme, brindarme comerciales, legales, servicios, noticias, noticias, seguridad, o aspectos relacionados con la empresa cuyo contenido considere necesario y/o apropiados para la correcta prestaci&oacute;n de los servicios adquiridos con BANCANNABIS.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              b).&nbsp;<span style="font-weight: 400;">Ser consultado, comparado, verificado, compartido y actualizado con los datos ante las entidades de consultas de bases de datos, listas vinculantes, o cualquier entidad que gestione y administre bases de datos para fines legalmente definidos para este tipo de entidades.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              c).&nbsp;<span style="font-weight: 400;">Autoriza a BANCANNABIS y sus ALIADOS a confrontar y verificar la veracidad de sus datos personales y los derivados de sus tarjetas de cr&eacute;dito y/o d&eacute;bito podr&aacute;n ser confrontados, verificados y reportar los mismos&nbsp; a los Aliados y seg&uacute;n corresponda para poder perfeccionar las operaciones de cambio y confirmar la existencia de tales medios.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              d).&nbsp;<span style="font-weight: 400;">La naturaleza y calidad de los Servicios de Intercambio Electr&oacute;nico (pasarela de pago no BANCANNABIS) utilizados a trav&eacute;s de la PLATAFORMA y en los que se solicita la informaci&oacute;n personal del USUARIO, son responsabilidad exclusiva de los ALIADOS y USUARIOS COLABORADORES.</span>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol>
            <li style="font-weight: 400;">
              e).&nbsp;<span style="font-weight: 400;">Al registrarse con los servicios de BANCANNABIS es necesario suministrar el n&uacute;mero de celular, correo electr&oacute;nico, n&uacute;mero de identificaci&oacute;n o pasaporte, y dem&aacute;s datos sencibles necesarios para identificar nuestros usuarios y evitar el fraude, el lavado de dinero y la financiacion del terrorismo. Por tanto, en el momento en que informe a BANCANNABIS la intenci&oacute;n de prescindir de sus servicios y que se traten estos datos, BANCANNABIS proceder&aacute; a cumplir con la orden de revocaci&oacute;n, el contrato finalizar&aacute; y los datos permanecer&aacute;n durante 10 a&ntilde;os en un repositorio blockchain ejecutado por medio de un contrato inteligente en la red descentralizada de Ethereum y solo estar&aacute; disponible si las autoridades competentes as&iacute; lo requiera o para procesos de auditor&iacute;a interna y externa del manejo de datos e informaci&oacute;n.</span>
            </li>
          </ol>
          <p><strong>&nbsp;</strong></p>
          <ol start="13">
            <li><strong> PRESCINDIR DE LOS SERVICIOS DE BANCANNABIS Y LA CUENTA DE USUARIO.</strong></li>
          </ol>
          <p><strong><br></strong><span style="font-weight: 400;">Para prescindir de los servicios y/o de la cuenta de USUARIO en la PLATAFORMA de BANCANNABIS se deber&aacute; enviar un correo electr&oacute;nico a: contac@bancannabis.org&nbsp; con la palabra "un-suscribe" en el asunto, para prescindir de la cuenta de USUARIO o &ldquo;cancel service&rdquo; para prescindir de un servicio y/o bien adquirido.</span></p>
          <p>&nbsp;</p>
          <ol start="14">
            <li>13.&nbsp;<strong> REQUERIMIENTOS T&Eacute;CNICOS PARA LA UTILIZACI&Oacute;N DE LA PLATAFORMA DE BANCANABIS</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Para acceder a la PLATAFORMA desde una computadora, se requiere contar con:</span></p>
          <p>&nbsp;</p>
          <p><strong>Software</strong></p>
          <p><span style="font-weight: 400;">Sistema operativo Windows, Linux, Mac OS</span></p>
          <p><span style="font-weight: 400;">Navegadores Google Chrome, Mozilla, Safari, otros.</span></p>
          <p>&nbsp;</p>
          <p><strong>Hardware</strong></p>
          <p><span style="font-weight: 400;">Procesador Intel&reg; a 1,3 GHz o superior.</span></p>
          <p><span style="font-weight: 400;">Memoria RAM 1024 Mb superior.</span></p>
          <p><span style="font-weight: 400;">Teclado y Mouse.</span></p>
          <p><span style="font-weight: 400;">Pantalla de 19 pulgadas o superior.</span></p>
          <p><span style="font-weight: 400;">Conexi&oacute;n Wifi 1mb</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Para acceder a la PLATAFORMA desde un dispositivo m&oacute;vil como celular o tablet , se requiere contar con:</span></p>
          <p>&nbsp;</p>
          <p><strong>Software</strong></p>
          <p><span style="font-weight: 400;">Sistema operativo Windows Phone, Android, iOS</span></p>
          <p><span style="font-weight: 400;">Navegadores Google Chrome, Mozilla, Safari, Opera, otros.</span></p>
          <p>&nbsp;</p>
          <p><strong>Hardware</strong></p>
          <p><span style="font-weight: 400;">Pantalla de 4 pulgadas o superior</span></p>
          <p><span style="font-weight: 400;">Procesador m&iacute;nimo de 800MHZ o superior</span></p>
          <p><span style="font-weight: 400;">Memoria interna m&iacute;nima 512 MB o superior</span></p>
          <p><span style="font-weight: 400;">Conexi&oacute;n Wifi 802.11</span></p>
          <p>&nbsp;</p>
          <ol start="15">
            <li>15.&nbsp;<strong> ACUERDO DEL USUARIO&nbsp;</strong></li>
          </ol>
          <p><strong>&nbsp;</strong></p>
          <p><span style="font-weight: 400;">Salvo acuerdo en contrario entre el USUARIO Y BANCANNABIS, los presentes T&Eacute;RMINOS Y CONDICIONES GENERALES PARA USUARIOS - USO Y FUNCIONAMIENTO DE LOS BIENES Y SERVICIOS OFERTADOS POR BANCANNABIS constituyen la totalidad de lo acordado entre ambas partes en relaci&oacute;n con la prestacion del servicio por parte de BANCANNABIS y sus ALIADOS y COLABORADORES.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Salvo acuerdo en contrario, estas condiciones de uso y funcionamiento ser&aacute;n de aplicaci&oacute;n a cualquier Servicio que contrate el USUARIO en la PLATAORMA.</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En el supuesto que se declarar&aacute; la nulidad de cualquiera de los presentes t&eacute;rminos y condiciones, ello no afectar&aacute; al resto de los t&eacute;rminos y condiciones, los cuales continuar&aacute;n surtiendo sus efectos.</span></p>
          <p>&nbsp;</p>
          <ol start="16">
            <li>16.&nbsp;<strong> JURISDICCI&Oacute;N Y LEY APLICABLE</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">Este contrato se rige por las leyes vigentes en la Rep&uacute;blica de Colombia. Cualquier caso controvertido que surja de este acuerdo, existencia, vigencia, interpretaci&oacute;n, alcance y / o cumplimiento de la relaci&oacute;n jur&iacute;dica entre el USUARIO Y BANCANNABISt, ambos renuncian expresamente a cualquiera otra jurisdicci&oacute;n que pueda corresponderles y sin perjuicio de las normas imperativas sobre competencia judicial, se someten voluntariamente a los Juzgados y Tribunales de Colombia.&nbsp;</span></p>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">En caso que cualquier aparte de los t&eacute;rminos y condiciones del presente documento llegare a ser declarado nulo, inexistente y/o inaplicable por un organismo judicial y/o administrativo, BANCANNABIS y el USUARIO procurar&aacute;n dar aplicaci&oacute;n a los apartes restantes del presente documento, atendiendo a las intenciones que ten&iacute;an de que los servicios fueran prestados, las normas supletivas que resulten aplicables y finalmente a la equidad.</span></p>
          <p>&nbsp;</p>
          <ol start="17">
            <li>17.&nbsp;<strong> MODIFICACIONES A LA PLATAFORMA Y A LOS T&Eacute;RMINOS Y CONDICIONES</strong></li>
          </ol>
          <p>&nbsp;</p>
          <p><span style="font-weight: 400;">BANCANNABIS se reserva el derecho a modificar total o parcialmente la PLATAFORMA en cualquier momento pudiendo eliminar o agregar servicios de pago o gratuitos sin que para ello deba solicitar ning&uacute;n tipo de autorizaci&oacute;n del USUARIO; en caso que esto suceda, se remitir&aacute; a los USUARIOS un documento y/o notificaci&oacute;n electr&oacute;nica en la cual se detallen los cambios realizados a la plataforma y el impacto que los mismos tienen respecto de los presentes &ldquo;T&eacute;rminos y condiciones&rdquo; y/o la forma en que se prestar&aacute; el servicio, recibido el mensaje o notificaci&oacute;n electr&oacute;nica por parte del usuario y/o sus usuarios autorizados, aquellos podr&aacute;n aceptar los cambios y modificaciones o negarse a hacerlo, en este &uacute;ltimo caso el usuario podr&aacute; dar por terminada su vinculaci&oacute;n jur&iacute;dica con BANCANNABIS de manera unilateral y sin proceder al pago de perjuicios. En caso que el usuario de por terminado el contrato por la raz&oacute;n antes mencionada, BANCANNABIS tampoco se ver&aacute; obligada al reconocimiento y pago de perjuicios, costos de cancelaci&oacute;n y otros relacionados, no obstante sin perjuicio de lo anterior la PLATAFORMA, podr&aacute; transmitir notificaciones mediante su p&aacute;gina de inicio sobre los cambios realizados a los T&eacute;rminos y condiciones, dichas notificaciones ser&aacute;n consideradas como v&aacute;lidas y notificadas al usuario, para lo cual una vez realizada dicha modificaci&oacute;n si el USUARIO sigue accediendo y utilizando las herramientas de la plataforma, se entender&aacute; que acepta las modificaciones, de lo contrario deber&aacute; abstenerse de usarlas.</span></p>
          <p><br><br></p>
        </div>
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BModal, BSpinner,
} from 'bootstrap-vue'
import LogoBancannabisBlack from '@core/layouts/components/LogoBancannabisBlack.vue'
import ZerocarnizeroLogo from '@core/layouts/components/LogoZeroCarniZero.vue'
import HeaderEgroweedNft from '@core/layouts/components/HeaderLoginNft.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    LogoBancannabisBlack,
    HeaderEgroweedNft,
    ZerocarnizeroLogo,
    // BSV
    BSpinner,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      password2: '',
      status: '',

      // validation rules
      required,
      email,
      reffCode: this.$route.query.i,

      // register reqs
      doRegisterRequest: false,
      blockchainId: null,
      isZero: false,
      isGrowerMaster: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    if (!this.reffCode) {
      this.reffCode = ''
    }
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
    this.isZero = this.$route.params.iz === true || this.$route.params.iz
    // console.log(this.$route.params)
  },
  methods: {
    validationForm() {
      if (this.doRegisterRequest) return
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          if (this.password !== this.password2 || !this.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                icon: 'CloseIcon',
                variant: 'warning',
                text: 'Please fill all form fields to continue.',
              },
            })
            return
          }
          // cambiar estado accion registrar
          this.doRegisterRequest = true
          const userDetails = {
            email: this.regEmail,
            password: this.password,
            referral: (this.reffCode) ? this.reffCode : '',
            metamask: false,
            iz: this.isZero,
            isGm: this.isGrowerMaster,
          }
          // registrar usuario
          this.$http.post(`/user?nid=${this.blockchainId.id}`, userDetails)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Registered User',
                  icon: 'EditIcon',
                  variant: 'success',
                  text: 'Registration Successful! Please check your email inbox for more information.',
                },
              })
              // this.$httpBca.post('/auth/local/register', {
              //   username: userDetails.email.split('@')[0],
              //   email: userDetails.email,
              //   password: userDetails.password,
              // })
              this.doRegisterRequest = false
              // debugger
              // this.$router.push({ name: 'login', params: { iz: this.isZero } })
              this.$router.push({ name: 'welcome', params: { userData: response.data } })
            })
            .catch(() => {
              this.doRegisterRequest = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Alerta!',
                  icon: 'CloseIcon',
                  variant: 'warning',
                  text: 'We sorry, cant create the user at this moment, please try again.',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
